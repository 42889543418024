<template>
  <div >
            <div >
    <vue-dropzone
      :id="uid"
      :ref="uid"
      :options="options"
      :use-custom-slot="true"
      @vdropzone-file-added="addTmpFile"
      @vdropzone-upload-progress="uploadProgress"
      @vdropzone-success="success"
      @vdropzone-error="error"
      @vdropzone-thumbnail="updateDataUrl"
    >
      <div class="dropzone-container" >
        <div class="file-selector">
          <figure>
            <label>{{$t('Attach')}}</label>
                    <div class="input-group mb-3">
  <input type="text" class="form-control" disabled :value="singleFile" placeholder="No File Chosen" aria-label="Recipient's username" aria-describedby="basic-addon2">
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2">{{$t('Browse')}}</span>
  </div>
</div>
          </figure>
          
        </div>
      </div>

      <div class="file-preview-container" v-show="false">
        <component
          :is="previewComponent"
          :key="childKey"
          :tmp-attachments="tmpAttachments"
          :attachments="media"
          @tmp-file-removed="handleTmpFileRemoved"
          @file-removed="fileRemoved"
        ></component>
      </div>
    </vue-dropzone>

    
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import AttachmentList from './AttachmentList'
import AttachmentPictures from './AttachmentPictures'
import cookie from "cookie";
import _ from "lodash";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    AttachmentList,
    AttachmentPictures
  },
  props: {
    accept: {
      type: String,
      default: null
    },
    component: {
      type: String,
      default: 'list',
      validator: value => {
        return ['list', 'pictures'].indexOf(value) !== -1
      }
    },
    route: {
      type: String,
      required: true
    },
    maxFiles: {
      type: Number,
      default: null
    },
    maxFileSize: {
      type: Number,
      default: 2
    },
    collectionName: {
      type: String,
      required: true
    },
    media: {
      type: Array,
      default: function () {
        return []
      }
    },
    modelId: {
      type: [String, Number],
      default: 0
    }
  },
  data() {
    return {
      hasFiles: false,
      childKey: 1,
      tmpAttachments: [],
      options: {
        url: this.route,
        maxFiles: this.maxFiles,
        maxFilesize: this.maxFileSize,
        acceptedFiles: this.accept,
        thumbnailWidth: 120,
        addRemoveLinks: true,
        timeout: 0,
        params: {
          size: this.maxFileSize,
          collection_name: this.collectionName,
          model_id: this.modelId
        },
        previewsContainer: '.attachment-list-container',
        previewTemplate: '<div class="d-none"></div>',
        headers: {
          
          "Authorization" : 'Bearer '+ localStorage.getItem('token'),
        }
      },
      textFile:'',
      singleFile:''
    }
  },
  mounted(){
    
    //console.log(localStorage.getItem('token'))
    for(var x=0 ; x<this.media.length ; x++){
      
      this.textFile = this.textFile + this.media[x].file_name + ','
    }
    
    
  },
  computed: {
    uid: function () {
      return `dropzone-${this._uid}`
    },
    previewComponent: function () {
      return `attachment-${this.component}`
    }
  },
  watch: {
    tmpAttachments: {
      handler: function () {
        this.hasFiles = this.media.length + this.tmpAttachments.length
      },
      deep: true
    },
    media: {
      handler: function () {
        this.hasFiles = this.media.length + this.tmpAttachments.length
        if (this.maxFiles === null) {
          return
        }
        let dz = this.$refs[this.uid]
        let fo = this.media.filter(item => item.wasRecentlyCreated !== true)
        dz.dropzone.options.maxFiles = this.maxFiles - fo.length
      },
      deep: true
    }
  },
  methods: {
    addTmpFile(file) {
      let attachment = {}
      attachment.uuid = file.upload.uuid
      attachment.id = null
      attachment.file_name = file.name
      attachment.size = file.size
      attachment.progress = null
      attachment.errorMsg = null
      attachment.o = file
      attachment.preview_thumbnail = null
      this.tmpAttachments.push(attachment)
      this.singleFile = file.name
      
    },
    uploadProgress(file, progress, bytesSent) {
      this.tmpAttachments.map(attachment => {
        if (attachment.uuid === file.upload.uuid) {
          attachment.progress = `${Math.floor(progress)}`
        }
      })
    },
    success(file, response) {
      let model = response
      model.wasRecentlyCreated = true

      this.fileUploaded(model)
    },
    error(file, message, xhr) {
      this.tmpAttachments.map(attachment => {
        if (attachment.uuid === file.upload.uuid) {
          attachment.errorMsg = message
        }
      })
    },
    handleTmpFileRemoved(file) {
      console.log(file)
      if (_.includes(['canceled', 'error'], file.o.status)) {
        this.removeTmpFile(file)
        return
      }

      if (file.o.status === 'uploading') {
        this.$refs[this.uid].removeFile(file.o)

        return
      }

      if (file.o.status === 'success') {
        this.removeTmpFile(file)
        this.fileRemoved(file)
        this.$refs[this.uid].removeFile(file.o)

        return
      }

      if (file.o.status === 'queued') {
        this.cancelQueuedFile(file)
        this.$refs[this.uid].removeFile(file.o)

        return
      }
    },
    removeTmpFile(file) {
      this.tmpAttachments = this.tmpAttachments.filter(attachment => {
        return attachment.uuid !== file.o.upload.uuid
      })
    },
    cancelQueuedFile(file) {
      this.tmpAttachments.map(attachment => {
        if (attachment.uuid === file.o.upload.uuid) {
          attachment.o.status = 'canceled'
          attachment.errorMsg = 'Upload canceled.'
        }
      })
    },
    updateDataUrl(file, dataURL) {
      this.tmpAttachments.map(attachment => {
        if (attachment.uuid === file.upload.uuid) {
          attachment.preview_thumbnail = dataURL
        }
      })
    },
    fileRemoved(file) {
      this.$emit('file-removed', file)
    },
    fileUploaded(file) {
      this.$emit('file-uploaded', file)
    }
  }
}
</script>

<style scoped>
.input-group-text:hover{
  cursor: pointer;
}
</style>


