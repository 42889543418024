<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Transactions')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('TransactionsDescription')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-warning mr-2"
          @click="showModal"
          ref="kt_save_changes"
        >
        {{$t('CreateTransaction')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          
          <div class="col-xl-12 px-0">
            <div class="form-group ">
              <b-table :items="items" :fields="$t('clan')=='en'?fields:fieldsAR" show-empty stacked="xl" responsive="xl">
                 <template #cell(status)="row">
          <div class="">
            <b-badge v-if="row.item.status==='waiting'" variant="warning" style="font-size:10px">{{row.item.status}}</b-badge>
            <b-badge v-if="row.item.status==='completed'" variant="success" style="font-size:10px">{{row.item.status}}</b-badge>
            <b-badge v-if="row.item.status==='rejected'" variant="danger" style="font-size:10px">{{row.item.status}}</b-badge>
          </div>
        </template>

                <template #cell(amount)="row">
                  <p>{{ row.item.amount + ' ' + "JOD" }}</p>
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>
            </div>
          </div>
          
        </div>
        <!--begin::Form Group-->
        
        <!--begin::Form Group-->
        

        

      </div>
    </form>
    <!--end::Form-->
    <b-modal
                    id="modal-1"
                    modal-class="modal"
                    ref="my-modal"
                    centered
                    hide-header
                    hide-footer
                    size="md"        
                               
                    
                  >
                  <div class="row">
                    <div class="col-sm-12   text-right " @click="hideModal"><i class="fas fa-times mr-5 cl"></i></div>

                    <div class="col-sm-12 text-center">
                      <h3 style="font-weight:600">{{$t('CreateTransaction')}}</h3>
                      </div>
                      <div class="col-sm-12 mb-5"></div>

                      <div :class="$t('clan')==='ar'?'col-sm-12   text-right':'col-sm-12  '" :dir="$t('clan')==='ar'?'rtl':'ltr'" >
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Amount')}}<span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <b-form-input
                      v-model="transaction.amount"
                        type="number"
                        class="form-control form-control-solid form-control-lg form__input"
                      :class="{
                          'form-input--error': $v.transaction.amount.$error,
                        }"
                       
                      />
                      
                    </div>
                  </div>


                  <div :class="$t('clan')==='ar'?'col-sm-12   text-right':'col-sm-12  '" :dir="$t('clan')==='ar'?'rtl':'ltr'" >
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">{{$t('Description')}}<span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <b-form-input
                      v-model="transaction.description"
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                      :class="{
                          'form-input--error': $v.transaction.description.$error,
                        }"
                       
                      />
                      
                    </div>
                  </div>

                  <div :class="$t('clan')==='ar'?'col-sm-12   text-right':'col-sm-12  '" :dir="$t('clan')==='ar'?'rtl':'ltr'" >
                      <div
                      class="form-group"
                      
                    >
                      <label class="labe">Type<span class="text-danger " style="font-size:15px; font-weight:bold">*</span></label>
                      <select
              class="form-control form-control-lg form-control-solid"
              v-model="transaction.type"
            >
              <template v-for="type in types">
                <option
                  :key="type.id"
                  :value="type.value"
                  :selected="type.value == transaction.type ? true : false"
                >
                  {{ type.label }}
                </option>
              </template>
            </select>
                      
                    </div>
                  </div>
                  </div>
            <!--End Modal Content Row-->
                         
                         

                        
                    <!--Modal Footer Buttons-->  
              <div class="buttons text-center  mt-5">
                  <b-button variant="white" class="mr-3  bg-secondary" @click="hideModal"
                    >{{$t('cancel')}}</b-button
                  >
                  <b-button variant="primary"  @click="create" >{{$t('Create')}}</b-button>
                </div>
              
              <!--End Modal Footer Buttons--> 
        </b-modal>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Transactions",

  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  async mounted(){
    var response = await this.$store.dispatch('drivers/Transactions',this.driver)
    //console.log(response.data.data)
    this.items = response.data.data
  },
  data() {
    return {
      transaction:{
        driver_id:this.driver.id,
        amount:'',
        description:"",
        //type:''
      },
      types:[
        {id:1, label:'debit',value:'cashout'},
        {id:2, label:'credit',value:'cashin'}
        ],
        fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
          //class: "text-center",
        },
        {
          key: "amount",
          label: "Amount",
          sortable: true,
          //class: "text-center",
        },
        {
          key: "description",
          label: "Description",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "type",
          label: "Type",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "order_id",
          label: "Order ID",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "commision",
          label: "Commission",
          //sortable: true,
         // class: "text-center",
        },
        {
          key: "earning",
          label: "Earning",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "balance_after",
          label: "balance after",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "created_by",
          label: "Created by",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          //sortable: true,
          //class: "text-center",
        },

        
      ],
      fieldsAR: [
        {
          key: "id",
          label: "المعرف",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "date",
          label: "التاريخ",
          sortable: true,
          //class: "text-center",
        },
        {
          key: "amount",
          label: "المبلغ",
          sortable: true,
          //class: "text-center",
        },
        {
          key: "description",
          label: "الوصف",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "type",
          label: "النوع",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "order_id",
          label: "رقم الطلب",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "commission",
          label: "العمولة",
          //sortable: true,
         // class: "text-center",
        },
        {
          key: "earning",
          label: "التحصيل",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "balance_after",
          label: "الرصيد بعد",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "created_by",
          label: "أنشئت بواسطة",
          //sortable: true,
          //class: "text-center",
        },
        {
          key: "status",
          label: "الحالة",
          //sortable: true,
          //class: "text-center",
        },
        ],

        items:[
    ]
  
    }

    
  },
  validations: {
    driver: {
      tokan: {
        required,
      },
      mac: {
        required,
      },
    },

    transaction:{
      amount:{
        required
      },
      description:{
        required
      }
    }
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      
        // dummy delay
        setTimeout(() => {
          // send update request
        this.$store.dispatch("drivers/update", this.driver);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          Swal.fire({
            title: "",
            text: "Driver has been update successfully!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        }, 2000);

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    cancel() {},
    showModal() {
      this.$refs["my-modal"].show();
      
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    async create(){
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        await this.$store.dispatch('drivers/CreateTransaction',this.transaction)
          
           await this.$store.dispatch('drivers/Transactions',this.driver).then((res)=>{
             console.log(res)
              this.items = res.data.data
              this.hideModal()
           })
    //console.log(response.data.data)
   

    
        }
       
      
    }
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>