<template>
  <div class="d-flex flex-row" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end">
            <KTDropdown2></KTDropdown2>
          </div>
          <div class="d-flex align-items-center">
            <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <div
                class="symbol-label mb-5"
                :style="{
                  backgroundImage: `url('https://www.udutu.com/wp-content/uploads/2016/08/courier.png')`,
                }"
              ></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary mr-5"
                >{{ driver.full_name }}</a
              >
              <div class="mt-2"></div>
            </div>
          </div>
          <!--end::User-->

          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
            dir="ltr"
          >
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="true"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="fas fa-user"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('Account Information')}}</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="fas fa-sliders-h"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('System Configurations')}}</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                   <i class="fas fa-envelope-open-text"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('Email Settings')}}</span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="3"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="fas fa-paperclip"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('Attachments')}} </span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="4"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="far fa-file-alt"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('Transactions')}} </span>
              </a>
            </div>

            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                style="cursor: pointer"
                data-tab="5"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <i class="fas fa-calendar-week"></i>
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('ScheduleInformation')}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div :class="$t('clan')=='en'?'flex-row-fluid ml-lg-8':'flex-row-fluid mr-lg-8'">
      <b-tabs class="hide-tabs" v-model="tabIndex" :no-nav-style="true">
        <b-tab active>
          <AccountInformation :driver="driver"></AccountInformation>
        </b-tab>

        <b-tab>
          <SystemConfigration :driver="driver"></SystemConfigration>
        </b-tab>

        <b-tab>
          <EmailPassword :driver="driver"></EmailPassword>
        </b-tab>

        <b-tab>
          <AttachmentInformation :driver="driver"></AttachmentInformation>
        </b-tab>

        <b-tab>
          <Transactions :driver="driver"></Transactions>
        </b-tab>

        <b-tab>
          <ScheduleInformation :driver="driver"></ScheduleInformation>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTDropdown2 from "@/view/content/dropdown/Dropdown2";
import AccountInformation from "@/view/pages/Drivers/view-comp/AccountInformation";
import SystemConfigration from "@/view/pages/Drivers/view-comp/SystemConfigration";
import AttachmentInformation from "@/view/pages/Drivers/view-comp/AttachmentInformation.vue";
import Transactions from "@/view/pages/Drivers/view-comp/Transactions.vue"
import EmailPassword from './view-comp/EmailPassword.vue'
import ScheduleInformation from './view-comp/ScheduleInformation.vue'

export default {
  name: "ViewDriver",
  components: {
    KTDropdown2,
    AccountInformation,
    SystemConfigration,
    AttachmentInformation,
    Transactions,
    EmailPassword,
    ScheduleInformation
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    driver() {
      return (
        this.drivers.find((driver) => driver.id == this.$route.params.id) || {}
      );
    },
    ...mapState({
      drivers: (state) => state.drivers.drivers,
    }),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Drivers", route: "/admin/drivers" },
      { title: "View Driver" },
    ]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
  },
};
</script>
