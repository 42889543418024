<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">{{$t('Attachments')}}</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Attachments')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>

    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <div>
          <div class="row">
            <div class="col-xl-5">
              <div class="form-group">
                <label>{{$t('name')}}</label>
                <b-form-input
                  type="text"
                  class="form-control form-control-solid form-control-md"
                  name="aname"
                  placeholder="Name"
                  v-model="Filename"
                />
              </div>
            </div>
            <div class="form-group col-xl-7" dir="ltr">
              <attachment
                :route="getRoute('drivers')"
                :collection-name="'driver_attachments'"
                :media="driver.attachments"
                :model-id="$route.params.id"
                :max-file-size="10"
                @file-uploaded="insertAttachmentsFile"
                @file-removed="removeAttachmentsFile"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="form-group row">
              <b-table :items="items" :fields="$t('clan')=='en'?fields:fieldsAR" show-empty>
                <template #cell(actions)="row">
                  <div class="text-center">
                    <button
                      @click.prevent="showModal(row.item, 'my-modal')"
                      class=""
                    >
                      <i class="far fas fa-eye"></i>
                      {{$t('view')}}
                    </button>

                    <button
                      @click.prevent="showModal(row.item, 'my-modal1')"
                      class="ml-4"
                    >
                      <i class="fas fa-trash mr-2 ml-2"></i>{{$t('Delete')}}
                    </button>
                  </div>
                </template>

                <template #cell(created_at)="row">
                  <p>{{ row.item.created_at.split("T")[0] }}</p>
                </template>

                <template #row-details="row">
                  <b-card>
                    <ul>
                      <li v-for="(value, key) in row.item" :key="key">
                        {{ key }}: {{ value }}
                      </li>
                    </ul>
                  </b-card>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title="$t('View Image')"
      hide-footer
      
      
    >
      <div class="text-center">
        <img :src="url" width="450" height="350" class="rounded" alt="..." />
        <!--<iframe :src="url" frameborder="0"></iframe>-->
       
      </div>
    </b-modal>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal1"
      :title="$t('Delete Attachment')"
      hide-footer
    >
      <p v-if="$t('clan')=='en'">Are you sure that you want to delete {{ attname }} ?</p>
      <p v-else>هل أنت موافق على حذف {{attname}}</p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >{{$t('cancel')}}</b-button
        >
        <b-button variant="danger" @click="removeAttachmentsFile(id)"
          >{{$t('Delete')}}</b-button
        >
      </div>
    </b-modal>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import Attachment from "../../../../assets/js/components/Attachments/Attachment";

export default {
  name: "SystemConfiguration",

  components: {
    Attachment,
  },
  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: this.driver.attachments,
      url: "",
      id: "",
      attname: "",
      Filename: "",
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "created_at",
          label: "Date",
          sortable: true,
          class: "text-center",
        },

        { key: "actions", label: "Actions" },
      ],
      fieldsAR: [
        {
          key: "name",
          label: "الإسم",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "created_at",
          label: "التاريخ",
          sortable: true,
          class: "text-center",
        },

        { key: "actions", label: "إعدادات" },
      ],
    };
  },
  mounted(){
    //console.log(this.items)
  },
  methods: {
    showModal(item, ref) {
      //console.log(item)
      if (ref === "my-modal") {
        this.$refs[ref].show();
        this.view(item);
      } else if (ref === "my-modal1") {
        this.$refs[ref].show();
        this.attname = item.file_name;
        this.id = item.id;
      }
    },
    hideModal() {
      this.$refs["my-modal1"].hide();
    },
    getRoute(name) {
      
      return `${this.axios.defaults.baseURL}api/v1/${name}/media`;
    },
    view(item) {
      //console.log(item)
      //console.log(this.axios.defaults.baseURL+'storage')
    //console.log(this.axios.defaults.baseURL+`/storage/${item.id}/${item.name}`+'.'+`${item.mime_type.split('/')[1]}`)
    if(item.url){
      this.url = item.url;
    }else{
      this.url= this.axios.defaults.baseURL+`/storage/${item.id}/${item.file_name}`
    }

    
    //this.url= this.axios.defaults.baseURL+`/storage/${item.id}/${item.file_name}`
      
    },
    insertAttachmentsFile(file) {
      //console.log(file)
      
      if (this.Filename === "") {
        file.name = file.file_name;
        
        this.driver.attachments.push(file);
      } else {
        file.name = this.Filename;

        this.driver.attachments.push(file);
        this.Filename = "";
      }
    },
    removeAttachmentsFile(id) {
      this.driver.attachments = this.driver.attachments.filter((item) => {
        return item.id !== id;
      });
      this.items = this.driver.attachments;
      this.hideModal();
    },
    save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        this.driver.fees_value = this.driver.fees_value.toString()
        // send update request
        this.$store.dispatch("drivers/update", this.driver);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        Swal.fire({
          title: "",
          text: "Driver has been update successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
      }, 2000);
    },
    cancel() {},
  },
};
</script>
