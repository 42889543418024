<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Account Information')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Change your account settings')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
          
        </div>
        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.driver.full_name.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Full Name')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="driver.full_name"
              :class="{ 'form-input--error': $v.driver.full_name.$error }"
              :state="$v.driver.full_name.$error === true ? false : null"
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.driver.phone.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('phone')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="number"
              v-model="driver.phone"
              :class="{ 'form-input--error': $v.driver.phone.$error }"
              :state="$v.driver.phone.$error === true ? false : null"
            />
          </div>
        </div>
        <!--begin::Form Group-->
        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.driver.national.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('National ID')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="number"
              v-model="driver.national"
              :class="{ 'form-input--error': $v.driver.national.$error }"
              :state="$v.driver.national.$error === true ? false : null"
            />
          </div>
        </div>
        <!--begin::Form Group-->

        <div
          class="form-group row"
          :class="{ 'form-group--error row': $v.driver.birth_date.$error }"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Birth Date')}}</label>
          <div class="col-lg-9 col-xl-6">
            <b-form-input
              class="form-control form-control-lg form-control-solid"
              type="date"
              v-model="driver.birth_date"
              :class="{ 'form-input--error': $v.driver.birth_date.$error }"
              :state="$v.driver.birth_date.$error === true ? false : null"
            />
          </div>
        </div>

        

        <div class="form-group row"
        :class="{ 'form-group--error row': $v.driver.vehicle_type.$error }">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Vehicle Type')}}</label>
          <div class="col-lg-9 col-xl-6">
            <select
              class="form-control form-control-lg form-control-solid"
              v-model="driver.vehicle_type"
              :class="{ 'form-input--error': $v.driver.vehicle_type.$error }"
              :state="$v.driver.vehicle_type.$error === true ? false : null"
            >
              <template v-for="type in types">
                <option
                  :key="type"
                  :value="type"
                  :selected="driver.vehicle_type == type ? true : false"
                >
                  {{ $t(type) }}
                </option>
              </template>
            </select>
          </div>
        </div>


         <!-- <div class="form-group row"
        :class="{ 'form-group--error row': $v.driver.selected_tarrif.$error }">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('SelectedTarrif')}}</label>
          <div class="col-lg-9 col-xl-6">
            <select
              class="form-control form-control-lg form-control-solid"
              v-model="driver.selected_tarrif"
              :class="{ 'form-input--error': $v.driver.selected_tarrif.$error }"
              :state="$v.driver.selected_tarrif.$error === true ? false : null"
            >
              <template v-for="tarrif in tarrifs">
                <option
                  :key="tarrif.id"
                  :value="tarrif.id"
                  :selected="tarrif.id == driver.selected_tarrif ? true : false"
                >
                  {{ tarrif.tarrif_name }}
                </option>
              </template>
            </select>
          </div>
        </div>-->

        <div
          class="form-group row"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Balance')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="number"
              v-model="driver.balance"
              disabled
            />
          </div>
        </div>

        <div
          class="form-group row"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Earning')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="number"
              v-model="driver.earning"
              disabled
            />
          </div>
        </div>


      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AccountInformation",

  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      types:['car','scooter','bicycle'],
      //modes:['fixed','percentage'],
      tarrifs:[],
    };
  },
  validations: {
    driver: {
      full_name: {
        required,
      },
      phone: {
        required,
      },
      national: {
        required,
      },
      birth_date: {
        required,
      },
      vehicle_type:{
        required
      },
    },
  },
  async mounted(){
    //console.log(this.driver)
    /*var tarrifs = await this.$store.dispatch('clientTarrif/loadAll')
    this.tarrifs = tarrifs.data*/
    //console.log(this.driver)
    //this.driver.selected_tarrif = 1
    

  },
  methods: {
    save() {
      
      this.driver.name = this.driver.full_name
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        //this.driver.fees_value = this.driver.fees_value.toString()
        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store.dispatch("drivers/update", this.driver).then((res=>{
            if(res.meta == null){
            Swal.fire({
          title: "",
          text:'The driver has been updated successfully',
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
          }else{
            Swal.fire({
          title: "",
          text:JSON.stringify(res.error),
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
          }
          }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          
        }, 2000);

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
    cancel() {},
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>