<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('Email Settings')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('Change your Email/Password Settings')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <div class="row">
          <label class="col-xl-3"></label>
          
        </div>
        <!--begin::Form Group-->
      
        <!--begin::Form Group-->
       
        <!--begin::Form Group-->
        
        <!--begin::Form Group-->

        

        <div
          class="form-group row"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Email')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="email"
              v-model="driver.email"
              
            />
          </div>
        </div>

        <div
          class="form-group row"
        >
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('Password')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="password"
              v-model="driver.password"
              
            />
          </div>
        </div>

      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";


export default {
  name: "AccountInformation",

  props: {
    driver: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      types:['car','scooter','bicycle'],
      //modes:['fixed','percentage'],
      tarrifs:[],
    };
  },
 

  async mounted(){
    //console.log(this.driver)
    /*var tarrifs = await this.$store.dispatch('clientTarrif/loadAll')
    this.tarrifs = tarrifs.data*/
    

  },
  methods: {
    save() {
      this.driver.name = this.driver.full_name
     
     
        //this.driver.fees_value = this.driver.fees_value.toString()
        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          // send update request
          this.$store.dispatch("drivers/update", this.driver).then((res=>{
            if(res.meta == null){
            Swal.fire({
          title: "",
          text:'The driver has been updated successfully',
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });
          }else{
            Swal.fire({
          title: "",
          text:JSON.stringify(res.error),
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
          }
          }));

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );

          
        }, 2000);

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
    
    },
    cancel() {},
  },
};
</script>


<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>